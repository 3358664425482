<div class="modal-header">
    <h2 class="modal-title">Cancelar reserva</h2>
    <button type="button" class="close btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">

            <!-- Mensaje de exito -->
            <div class="alert alert-primary" role="alert" *ngIf="showRegisterSuccess">
                <strong></strong>
            </div>

            <!-- Mensaje de error -->
            <div class="alert alert-danger" role="alert" *ngIf="showRegisterError">
                <strong></strong>
            </div>

            <!-- Usamos formulario reactivo -->
            <form [formGroup]="formRegister">

                <!-- Usuario -->
                <div class="row form-group">
                    <div class="col-12">
                        <label for="user">Código de reserva</label>
                        <input type="text" placeholder="Por favor, inserta tu código de reserva" formControlName="codigoReserva" class="form-control" id="user" />
                    </div>
                </div>




                <div class="row form-group">
                    <div class="col-12">
                        <button type="submit" (click)="cancelarReserva()" class="btn btn-block w-100 btn-primary">Cancelar Reserva</button>
                    </div>
                </div>




            </form>

        </div>
    </div>
</div>
