import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { take } from 'rxjs/operators';


import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ReservasService } from '../component/services/reservas.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Importa la configuración regional de español
interface Reserva {
  hours: any[]; // Reemplaza 'any' con el tipo adecuado para el array 'hours'
  // Otras propiedades de la reserva si las hay
}

@Component({
  selector: 'app-cancelar',
  templateUrl: './cancelar-reserva.component.html',
  styleUrls: ['./cancelar-reserva.component.css']


})
export class CancelarReservaComponent implements OnInit {

  constructor(private route: ActivatedRoute, private reservasService: ReservasService, private router: Router, private http: HttpClient,
  ) { }
  id: string;
  formulario: FormGroup;
  reservasBloqueadas: Reserva[];
  codigoReserva: string;
  hours: any 
reserva_data: any
reserva_limpiar: any



  ngOnInit() {

    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.formulario = new FormGroup({
        codigoReserva: new FormControl(this.id)
      });
    });











  }

  verBloqueo() {

        // const codigoReserva = this.formulario.get('codigoReserva').value;

    this.codigoReserva = this.formulario.get('codigoReserva').value;

    this.reservasService.buscarReservasBloqueadas(this.codigoReserva).pipe(take(1)).subscribe(
      (reservas) => {

    
        if (reservas.length > 0) {
          reservas.forEach((reserva) => {
           
            const reserv = reserva;
            this.reserva_limpiar = reserv



        this.reserva_data = reserv.reserva.hours[reserva.hour_index]

        if (!this.reserva_data) {
          Swal.fire('La reserva está vacía', '', 'warning');
        }
      

                  
          });
        } 

        if(reservas.length == 0){
          Swal.fire('Código de reserva no encontrado', 'Por favor, revisa bien tu email de confirmación, ahí lo encontrarás', 'warning')
        } 
      },
      (error) => {
        console.error('Error al buscar reservas bloqueadas:', error);
        Swal.fire('Por favor, revisa bien el código de la reserva', '', 'warning')

      }
    );
  }



  limpiarHour() {
    const id = this.reserva_limpiar.id;
    const index = this.reserva_limpiar.hour_index;
    const hour_start = this.reserva_limpiar.hour_start

    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro que deseas cancelar?',
      text: 'Si cancelas la reserva, se liberará la hora reservada y no podrás recuperarla. Para realizar una reserva nueva, deberás insertar tus datos de nuevo',
      showCancelButton: true,
      confirmButtonText: 'Cancelar Reserva',
      cancelButtonText: 'No', // Personaliza el texto del botón de cancelación aquí
    }).then((result) => {
      if (result.isConfirmed) {
       this.sendEmailInstructor(this.reserva_limpiar)
        this.limpiar(id, index, hour_start);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Ejecutar lógica si el usuario hace clic en el botón de cancelación
        Swal.fire('La reserva no fue cancelada', '', 'info');
      }
    });
    
  }
  

  limpiar(id, index, hour_start){



    const data = {

      user_client_id: '',
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      dni: '',
      examen: '',
      tramitacion: '',
      hour_start: hour_start,


  }



    this.reservasService.limpiarDatos2(id, index, data)
    .then(() => {
      Swal.fire('Reserva Cancelada', '', 'success')

      setTimeout(() => {
        this.router.navigate(['/dashboard']);
      }, 3000);


    })
    .catch((error) => {
      Swal.fire('No ha sido posible eliminar la reserva', '', 'warning')
      console.error('Error al actualizar el mapa', error);
    });
  }




  cancelarReserva() {



    this.limpiarHour()
 
  }


  sendEmailInstructor(item: any) {


    const fechaEnEspanol = format(new Date(item.reserva.date), 'dd/MM/yyyy', { locale: es });

    const dest = [item.reserva.email];

    const subject = 'Han cancelado una reserva ❌'
    const html = `<!DOCTYPE html>
    <html lang="es">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Nueva reserva</title>
      <style>
        body {
          font-family: Arial, sans-serif;
          font-size: 16px;
          margin: 0;
          padding: 0;
        }
    
        .container {
          max-width: 600px;
          margin: 20px auto;
          padding: 20px;
          border: 1px solid #ddd;
          border-radius: 8px;
        }
    
        h2 {
          color: #0077cc;
        }
    
        h3 {
          font-size: 1.2em;
          margin-bottom: 10px;
        }
    
        p {
          margin-bottom: 10px;
        }
    
        hr {
          border: 0;
          border-top: 1px solid #ddd;
          margin: 20px 0;
        }
    
        .footer {
          font-size: 12px;
          color: #999999;
          margin-top: 20px;
          border-top: 1px solid #ddd;
          padding-top: 10px;
        }
    
        .logo {
          display: block;
          margin: 0 auto;
          max-width: 100%;
        }
      </style>
    </head>
    <body>
      
      <div class="container">
    
        <img src="https://qualiflight.aero/wp-content/uploads/2020/04/Qualiflight-aviation-training-05-05-300x83.png" alt="Logo de Qualiflight" style="display: block; margin: 0 auto; max-width: 100%;">
    
    
        <h2>Reserva cancelada ❌</h2>
    
    
        <div> 🗓️ <strong>Día de reserva:</strong> ${fechaEnEspanol}</div>
        <div>⏰ <strong>Hora: </strong>${item.hour_start} </div>
        <div>📍 <strong>Lugar:</strong> Qualiflight ${item.reserva.city} </div>
    
        
    
    
        <p class="footer">Este correo electrónico fue generado automáticamente. Por favor, no responda a este mensaje.</p>
      </div>
    </body>
    </html>
    
    `;




    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        /*'Authorization': 'secret-key'*/
      })
    }

    const data = {html, subject, dest}

    const host = 'https://us-central1-reservas-20861.cloudfunctions.net/emailSender'


    for (let i = 0; i < dest.length; i++) {
      const data = {
        html,
        subject,
        dest: dest[i]
      }


          this.http.post(host, data, httpOptions).subscribe({
      next: (response) => {
      },
      error: (error) => {
      },
      complete: () => {
      }
    });


    }

  }




}
