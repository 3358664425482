import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../models/user-model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  // Atributos
  public options: string[];

  public formRegister: FormGroup;
  public showRegisterSuccess: boolean;
  public showRegisterError: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public db: AngularFirestore,
    private router: Router
  ) {// Creo el formGroup
    this.formRegister = this.formBuilder.group({
      user: new FormControl('', Validators.required),
      pass: new FormControl('', Validators.required)
    });
    this.showRegisterSuccess = false;
    this.showRegisterError = false;

    this.options = ['Inglés', 'Español', 'Inglés + Español'];

  }




  ngOnInit() {
  }

  checkRegister() {
    this.showRegisterSuccess = false;
    this.showRegisterError = false;

    // Compruebo si es correcto
    this.authService.authSingUp(this.formRegister.value.user,  this.formRegister.value.pass).then(() => {
      this.authService.authLogin(this.formRegister.value.user, this.formRegister.value.pass).then(() => {
        this.showRegisterSuccess = true;
        this.router.navigate(['/list-bookings']);
        this.activeModal.close();
      }).catch(() => {
        this.showRegisterError = true;
      });
    });

  }

}
