<div class="modal-header">
    <h3 class="modal-title">Reserva el día {{ fecha | date:'dd/MM/yyyy'}} a las {{ hoursArray[selectedIndex]?.hour_start }}

    </h3>
    <button class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()"><i class="fa fa-times"></i></button>

</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="row" id="add-booking">
                <div class="col-12">




                    <form [formGroup]="formRegister">
                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">Nombre</label>
                                <input type="text" formControlName="nombre" class="form-control" id="nombre" />
                                <div *ngIf="formRegister.get('nombre').invalid && formRegister.get('nombre').touched">
                                    <div *ngFor="let error of errorMessages.nombre" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">Apellidos</label>
                                <input type="text" formControlName="apellidos" class="form-control" id="apellidos" />
                                <div *ngIf="formRegister.get('apellidos').invalid && formRegister.get('apellidos').touched">
                                    <div *ngFor="let error of errorMessages.apellidos" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>


                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">DNI</label>
                                <input type="text" formControlName="dni" class="form-control" id="dni" />
                                <div *ngIf="formRegister.get('dni').invalid && formRegister.get('dni').touched">
                                    <div *ngFor="let error of errorMessages.dni" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>


                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">Nº Licencia</label>
                                <input type="text" formControlName="licencia" class="form-control" id="licencia" />
                                <div *ngIf="formRegister.get('licencia').invalid && formRegister.get('licencia').touched">
                                    <div *ngFor="let error of errorMessages.licencia" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>


                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">Teléfono</label>
                                <input type="text" formControlName="telefono" class="form-control" id="telefono" />
                                <div *ngIf="formRegister.get('telefono').invalid && formRegister.get('telefono').touched">
                                    <div *ngFor="let error of errorMessages.telefono" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-12">
                                <label for="text">Email</label>
                                <input type="email" formControlName="email" class="form-control" id="email" />
                                <div *ngIf="formRegister.get('email').invalid && formRegister.get('email').touched">
                                    <div *ngFor="let error of errorMessages.email" class="text-danger">{{ error.message }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="filter">Selecciona posición</label>
                            <select class="form-control" formControlName="tipo" id="tipo">
                      <option [selected]="first" [value]="option" *ngFor="let option of tipos; let first=first">{{ option }}</option>
                    </select>
                            <div *ngIf="formRegister.get('tipo').invalid && formRegister.get('tipo').touched">
                                <div *ngFor="let error of errorMessages.tipo" class="text-danger">{{ error.message }}</div>
                            </div>
                        </div>


                        <div class="form-group">
                            <label for="filter">Selecciona examen a realizar</label>
                            <select class="form-control" formControlName="examen" id="services">
                        <option [selected]="first" [value]="option" *ngFor="let option of options; let first=first">{{ option }}</option>
                      </select>
                            <div *ngIf="formRegister.get('examen').invalid && formRegister.get('examen').touched">
                                <div *ngFor="let error of errorMessages.examen" class="text-danger">{{ error.message }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="filter">Desea añadir formulario de tramitación?</label>
                            <select class="form-control" formControlName="tramitacion" id="tramitacion">
                        <option value="-">--</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                            <div *ngIf="formRegister.get('tramitacion').invalid && formRegister.get('tramitacion').touched">
                                <div *ngFor="let error of errorMessages.tramitacion" class="text-danger">{{ error.message }}</div>
                            </div>
                        </div>
                    </form>

                    <button style="width: 100%; margin-top: 30px;" class="btn btn-primary" (click)="changeStatus()" [disabled]="!isFormValid">
                      Reservar
                    </button>






                    <!-- <button style="width: 100%; margin-top: 30px;" class="btn btn-primary" (click)="sendEmail()">
                       Send Email
                     </button>

                    <button style="width: 100%; margin-top: 30px;" class="btn btn-primary" (click)="sendEmailInstructor()">
                      Send Email Examinador
                    </button> -->
                </div>
            </div>

            <!-- Templates para los modales -->

            <ng-template #modal_success let-modal>
                <div class="modal-header">
                    <h5 class="modal-title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body">

                </div>
            </ng-template>

            <ng-template #modal_exists let-modal>
                <div class="modal-header">
                    <h5 class="modal-title"></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss()">

          <span aria-hidden="true">&times;</span>
        </button>

                </div>
                <div class="modal-body">

                </div>
            </ng-template>
        </div>
    </div>
</div>