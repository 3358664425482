<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-end">
    <li class="nav-item nav-link" placement="bottom-end" *ngIf="!authService.isAuthenticated()">
        <button class="ms-2 btn btn-primary" (click)="openLogin()">Login</button>
    </li>

    <li class="nav-item nav-link" placement="bottom-end" *ngIf="!authService.isAuthenticated()">
        <button class="ms-2 btn btn-primary" (click)="openCancelar()">Cancelar Reserva</button>
    </li>

    <!-- <li class="nav-item nav-link" placement="bottom-end" *ngIf="!authService.isAuthenticated()">
        <button class="ms-2 btn btn-primary" (click)="openRegister()">Registrar</button>
    </li> -->

    <li class="nav-item nav-link" placement="bottom-end" *ngIf="authService.isAuthenticated()">
        <button class="ms-2 btn btn-primary" (click)="logout()">Logout</button>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <!-- <li class="nav-item" ngbDropdown placement="bottom-end" *ngIf="authService.isAuthenticated()">
      <a ngbDropdownToggle class="nav-link waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/users/user1.jpg" alt="user" class="rounded-circle" width="31" />
          <span class="ms-2">Steve Jobs</span>
      </a>
      <div class="dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0)"> My Profile</a>
          <a class="dropdown-item" href="javascript:void(0)"> My Balance</a>
          <a class="dropdown-item" href="javascript:void(0)"> Inbox</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)"> Account Setting</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0)"> Logout</a>
      </div>
  </li> -->
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>