import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../component/services/auth.service';
import { RegisterComponent } from '../../component/register/register.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // Atributos
  public formLogin: FormGroup;
  public showLoginSuccess: boolean;
  public showLoginError: boolean;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    // Creo el formGroup
    this.formLogin = this.formBuilder.group({
      user: new FormControl('', Validators.required),
      pass: new FormControl('', Validators.required)
    });
    this.showLoginSuccess = false;
    this.showLoginError = false;
  }

  ngOnInit() {
  }

  checkLogin() {

    // Reinicio para que no haya problemas tras varios intentos
    this.showLoginSuccess = false;
    this.showLoginError = false;

    // Compruebo si es correcto
    this.authService.authLogin(this.formLogin.value.user, this.formLogin.value.pass).then(() => {
        this.showLoginSuccess = true;
        // Redirijo a la lista de bookings
        this.router.navigate(['/component/calendario']);
        // Indico que estoy logueado
        localStorage.setItem('logged', '1');
        // Cierro la ventana
        this.activeModal.close();
    }).catch(err => {
      this.showLoginError = true;
    });
  }

  openRegister() {
    this.modalService.open(RegisterComponent);
    this.activeModal.close();
  }

}
