<div class="modal-header">
    <h5 class="modal-title">Reserva - {{ reserva.date | date:'dd/MM/yyyy' }}</h5>
    <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()"> -->
    <button class="btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()"><i class="fa fa-times"></i></button>


</div>
<div class="modal-body">
    <div class="row">

        <div class="col-12">

            <div class="row" id="add-booking">
                <div class="col-12">




                    <!-- Formulario reactivo -->
                    <form [formGroup]="formBooking" >


                        <div class="row form-group">
                            <div class="col-12 mt-2">
                                <select formControlName="city" id="city" class="form-control" [ngClass]="{
                                   'is-valid': formBooking.getRawValue().city.dirty && !formBooking.getRawValue().city.invalid,
                                   'is-invalid': formBooking.getRawValue().city.dirty && formBooking.getRawValue().city.invalid
                                }">
                                    <option value="" disabled selected>Selecciona una ciudad</option>
                                    <option value="Madrid">Madrid</option>
                                    <option value="Malaga">Malaga</option>
                                </select>
                        
                                <div class="valid-feedback">
                                    <span></span>
                                </div>
                                <div class="invalid-feedback">
                                    <span *ngIf="formBooking.getRawValue().city.errors?.required">Por favor, selecciona una ciudad.</span>
                                </div>
                            </div>
                        </div>

                        <!-- Nombre del usuario que hace la reserva -->
                        <div class="row form-group">
                            <div class="col-12 mt-2">
                                <label for="name"></label>
                                <input  type="text" formControlName="name" id="name" class="form-control" [ngClass]="{
                       'is-valid': formBooking.getRawValue().name.dirty && !formBooking.getRawValue().name.invalid,
                       'is-invalid': formBooking.getRawValue().name.dirty && formBooking.getRawValue().name.invalid
                     }">

                                <div class="valid-feedback">
                                    <span></span>
                                </div>
                                <div class="invalid-feedback">
                                    <span *ngIf="formBooking.getRawValue().name.errors?.required"></span>
                                </div>
                            </div>

                        </div>


                 
                        

                        <!-- Fecha de la reserva (p-calendar de primeng) -->
                        <!--<div class="row form-group">
            <div class="col-12 mt-2">
              <label for="date">{{'label.date' | translate}}</label>
              <p-calendar [locale]="locale" dateFormat="dd/mm/yy" id="date" formControlName="date"
                          [readonlyInput]="true" inputStyleClass="form-control is-valid"
                          [minDate]="today"></p-calendar>
            </div>
          </div>-->

                        <form *ngFor="let formHour of formHourList; let index = index" [formGroup]="formHour" class="mb-4">
                            <div class="row form-group">

                                <div class="col-6 mt-2">

                                    <input  style="margin-bottom: 10px;" id="hour_start" type="time" class="form-control" formControlName="hour_start">

                                    <input  *ngIf="ocultarEnd" class="form-control" id="hour_end" type="time" formControlName="hour_end">

                                </div>
                                <div class="col-6 mt-2 d-flex justify-content-end align-items-center botones" style="padding-top: 10px;">
                                    <div *ngIf="botonGuardarHora">
                                        <button  *ngIf="index === formHourList.length - 1" class="btn btn-warning" (click)="guardarHora(index)" style="padding: 5px; margin: 5px;"><a ngbTooltip="Guardar hora"><i class="fa fa-save"></i></a></button>
                                    </div>
                                    <div *ngIf="!botonGuardarHora">
                                        <button   *ngIf="index === formHourList.length - 1" class="btn btn-success" (click)="addHour(index)" style="padding: 5px; margin: 5px;"><a ngbTooltip="Agregar hora única"><i class="fa fa-plus-circle"></i></a></button>

                                    </div>
                                    <div *ngIf="botonGuardarIntervalo">
                                        <button  *ngIf="index === formHourList.length - 1" class="btn btn-info" (click)="asignarHour(index)" style="padding: 5px; margin: 5px;"><a ngbTooltip="Agregar franja horaria"><i class="fa fa-calendar-plus-o"></i></a></button>
                                    </div>
                                    <button  class="btn btn-info" (click)="limpiarHour(index)" style="padding: 5px; margin: 5px;"><a ngbTooltip="Limpiar fecha"><i class="fas fa-broom"></i></a></button>

                                    <button  title="eliminar" class="btn btn-danger" (click)="deleteHour(index)" style="padding: 5px; margin: 5px;"><a ngbTooltip="Eliminar"><i class="fa fa-trash"></i></a>
                                         </button>





                                </div>
                            </div>
                            <div *ngIf="reserva.hours" class="row form-group col-12">
                                <p *ngIf="reserva.hours[index].user_client_id === ''"> <button  (click)="cancelReserva(index)" class="btn btn-outline-warning" style="padding: 5px; margin: 5px;"><i class="fas fa-unlock"></i> Bloquear</button>
                                </p>

                                <div *ngIf="reserva.hours[index].user_client_id !== ''">

                                    {{reserva.hours[index].nombre}} {{reserva.hours[index].apellidos}} | {{reserva.hours[index].telefono}}


                                </div>
                                <div *ngIf="reserva.hours[index].user_client_id === 'fecha bloqueada'">

                                    <p><button  *ngIf="showCancelButton[index]" (click)="cancelar(index)" class="btn btn-outline-danger" style="padding: 5px; margin: 5px;"><i class="fas fa-window-close
                                      "></i> Cancelar</button></p>

                                    <p><button  *ngIf="!showCancelButton[index]" (click)="anadirDatos(index)" class="btn btn-outline-secondary" style="padding: 5px; margin: 5px;"><i class="fas fa-user-plus"></i> Añadir datos</button></p>
                                </div>
                                <div class="row form-group " *ngIf="showCancelButton[index]">
                                    <form [formGroup]="formGuardar">
                                        <div>
                                            <label for="nombre">Nombre:</label>
                                            <input  type="text" id="nombre" formControlName="nombre" />
                                        </div>
                                        <div>
                                            <label for="email">Email:</label>
                                            <input  type="email" id="email" formControlName="email" />
                                        </div>
                                        <div>
                                            <label for="telefono">Teléfono:</label>
                                            <input  type="text" id="telefono" formControlName="telefono" />
                                        </div>
                                    </form>


                                    <div class="col-12 " style="margin-top: 10px; ">

                                        <div class="d-inline-block ">
                                            <button  (click)="guardarDatos(index) " class="btn btn-primary " style="padding: 5px; margin: 5px; ">Guardar Datos</button>
                                        </div>
                                        <div class="d-inline-block ">
                                            <button  class="btn btn-secondary " (click)="desbloquearReserva(index)" style="padding: 5px; margin: 5px; ">Desbloquear </button>
                                        </div>
                                    </div>

                                </div>



                            </div>




                            <hr>
                        </form>



                        <!-- Submit -->
                        <div class="row form-group">
                            <div class="col-12">
                                <button  (click)="addBookingFirebase()" [disabled]="formBooking.invalid" type="submit" class="btn btn-block btn-primary" style="margin: 10px;" *ngIf="ocultarAgregar">
                Habilitar nuevos horarios
              </button>

                                <button (click)="deleteReserva()" class="btn btn-block btn-danger" style="margin: 10px;" *ngIf="!ocultarAgregar" >
                Eliminar todo
              </button>
                            </div>
                        </div>

                    </form>

                </div>
            </div>

            <!-- Templates para los modales -->

        </div>
    </div>
</div>