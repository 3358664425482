<nav class="sidebar-nav" style="padding-top: 20px;">
    <ul id="sidebarnav">
        <div *ngIf="profile !== 'client'">

            <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
                <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                    <i [ngClass]="[sidebarnavItem.icon]"></i>
                    <span class="hide-menu">{{sidebarnavItem.title}}</span>
                </a>
            </li>
        </div>
        <div *ngIf="profile !== 'admin'">
            <!-- <li class="sidebar-item" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">
                <a class="btn sidebar-link waves-effect waves-dark">
                    <i class="bi bi-house-door"></i>
                    <span class="hide-menu">Inicio</span>
                </a>
            </li> -->

            <li class="sidebar-item" [routerLink]="['component/booking']" routerLinkActive="router-link-active">
                <a class="btn sidebar-link waves-effect waves-dark">
                    <i class="bi bi-calendar-plus"></i>
                    <span class="hide-menu">Añadir Reserva</span>
                </a>
            </li>
        </div>





        <!-- <li class="text-center p-3 upgrade-btn">
            <a href="" class="btn d-block w-100 btn-primary">Contacto</a>
        </li> -->
    </ul>
</nav>