import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModel } from '../models/user-model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-register',
  templateUrl: './cancelar.component.html',
  styleUrls: ['./cancelar.component.css']
})
export class CancelarComponent implements OnInit {

  // Atributos
  public options: string[];

  public formRegister: FormGroup;
  public showRegisterSuccess: boolean;
  public showRegisterError: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public db: AngularFirestore,
    private router: Router
  ) {// Creo el formGroup
    this.formRegister = this.formBuilder.group({
      codigoReserva: new FormControl('', Validators.required),
    });
    this.showRegisterSuccess = false;
    this.showRegisterError = false;


  }




  ngOnInit() {
  }

  cancelarReserva() {
    const codigoReserva = this.formRegister.value.codigoReserva;

    this.db.collection('reservas').get().subscribe((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const reservaData = doc.data() as ReservaData; // <- Conversión de tipo
        const hoursArray = reservaData.hours;


        if (hoursArray) {

          for (const hoursData of hoursArray) {
            const userClientIds = hoursData.user_client_id;




            if (userClientIds.includes(codigoReserva)) {

            }
          }


        }
      });
    }, (error) => {
      console.error('Error al obtener los datos de la colección "reservas":', error);
    });
  }
}

interface ReservaData {
  hours: HoursData[]; // <- Asegura que 'hours' sea de tipo 'HoursData[]'
}

interface HoursData {
  user_client_id: string[]; // <- Asegura que 'user_client_id' sea de tipo 'string[]'
}
