<div class="modal-header">
    <h5 class="modal-title">Registrar</h5>
    <button type="button" class="close btn btn-primary" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">

            <!-- Mensaje de exito -->
            <div class="alert alert-primary" role="alert" *ngIf="showRegisterSuccess">
                <strong></strong>
            </div>

            <!-- Mensaje de error -->
            <div class="alert alert-danger" role="alert" *ngIf="showRegisterError">
                <strong></strong>
            </div>

            <!-- Usamos formulario reactivo -->
            <form [formGroup]="formRegister" (ngSubmit)="checkRegister()">

                <!-- Usuario -->
                <div class="row form-group">
                    <div class="col-12">
                        <label for="user">Email</label>
                        <input type="text" formControlName="user" class="form-control" id="user" />
                    </div>
                </div>




                <!-- <div class="row form-group">
                    <div class="col-12">
                        <label for="user">Exam</label>

                        <select class="custom-select" formControlName="examen" id="services">
                        <option [selected]="first"  [value]="option" *ngFor="let option of options;let first=first">{{option}}</option>
                      </select>
                    </div>
                </div> -->

                <!-- Password -->
                <div class="row form-group">
                    <div class="col-12">
                        <label for="pass">Pass</label>
                        <input type="password" formControlName="pass" class="form-control" id="pass" />
                    </div>
                </div>

                <!-- Submit -->
                <div class="row form-group">
                    <div class="col-12">
                        <button [disabled]="formRegister.invalid" type="submit" class="btn btn-block w-100 btn-primary">Registrar</button>
                    </div>
                </div>


            </form>

        </div>
    </div>
</div>
