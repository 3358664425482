import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservasService } from '../services/reservas.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import {takeUntil} from 'rxjs/operators';

import {Subject} from 'rxjs';


interface BookingData {
  hours: any[]; // O cualquier otro tipo de datos que tenga la propiedad hours
  // Agregar otras propiedades aquí si es necesario
}



@Component({
  selector: 'app-register',
  templateUrl: './reserva.component.html',
  styleUrls: ['./reserva.component.css']
})
export class ReservaComponent implements OnInit {
  @Input() reserva;
  private ngUnsubscribe: Subject<void> = new Subject();
  public users: any[] = [];
  public hours:  any[] = [];
  public options: string[];
  public locale: any;
  public today: Date;
  public formBooking: FormGroup;
  public formHourList: FormGroup[] = [];
  public ocultarEnd: boolean;
  ocultarAgregar: boolean = false;
  botonGuardarHora: boolean = false;
  botonGuardarIntervalo: boolean = false;
  public formGuardar: FormGroup;
  showCancelButton: boolean[] = [];

   private userModel = JSON.parse(localStorage.getItem(environment.userReservas));

  constructor(
    private formBuilder: FormBuilder,
    private reservasService: ReservasService,
    public activeModal: NgbActiveModal,
  ) {



  }

  ngOnInit() {


    // Creo el formGroup
    this.formBooking = this.formBuilder.group({
      name: new FormControl(this.reserva.name ?? '', Validators.required),
      city: new FormControl(this.reserva.city ?? '', Validators.required),

    });


    this.formBooking.get('city').setValue(this.reserva.city ?? '');


    if (this.reserva.hours) {
      this.reserva.hours.forEach(item => {
        this.formHourList.push(this.setFormHour(item));
        this.obtenerReserva2()
      });
    } else {
      this.formHourList.push(this.setFormHour());
      this.ocultarEnd = true;
      this.botonGuardarIntervalo = true;

    }


    this.formGuardar = this.formBuilder.group({
      nombre: new FormControl(''),
      email: new FormControl(''),
      telefono: new FormControl(''),
    });


    this.ocultarAgregarReserva();


  }

  ocultarAgregarReserva() {
    if (this.reserva.hours == null) {
      this.ocultarAgregar = true;
    } else {
      this.ocultarAgregar = false;
    }
  }

  private setFormHour(model = null): FormGroup {
    return this.formBuilder.group({
      hour_start: new FormControl(model ? model.hour_start : ''),
      hour_end: new FormControl(model ? model.hour_end : ''),
    });
  }

  addHora(index) {
    const id = this.reserva.id;

  const data = {}

    this.formHourList.splice(index + 1, 0, this.setFormHour(data));

    // Llamar al servicio para actualizar los datos en el backend
    // this.reservasService.agregarDatos(id, this.formHourList)
    //   .then(() => {
    //     this.reservasService.getuserby(id)
    //       .pipe(takeUntil(this.ngUnsubscribe))
    //       .subscribe((result: any) => {
    //         this.users = [result];
    //         this.hours = result.hours;

    //         this.formHourList = [];

    //         this.hours.forEach(item => {
    //           this.formHourList.push(this.setFormHour(item));
    //         });


    //       });
    //   })
    //   .catch((error) => {
    //     console.error('Error al actualizar el mapa', error);
    //   });
  }

  anadirDatos(index: number) {
    this.showCancelButton[index] = true
  }

  cancelar(index: number) {
    this.showCancelButton[index] = false
  }

  cancelReserva(index: number) {
    // this.showCancelButton[index] = false; // Ocultar el botón "Cancelar Reserva"
    // this.fechaBloqueada[index] = true; // Ocultar el botón "Cancelar Reserva"


    const hourList = [];


    const id = this.reserva.id

    const data = {

      user_client_id: 'fecha bloqueada',

  }




    this.reservasService.bloquearFecha(id, index, data)
    .then(() => {



    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });


  }


  limpiar(index){


    const id = this.reserva.id

    const data = {

      user_client_id: '',
      user_client_name: '',
      nombre: '',
      apellidos: '',
      email: '',
      telefono: '',
      dni: '',
      examen: '',
      tramitacion: '',
      licencia: '',
      tipo: ''


  }




    this.reservasService.limpiarDatos(id, index, data)
    .then(() => {



    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });
  }

  guardarDatos(index: number) {
    this.showCancelButton[index] = false; // Ocultar el botón "Cancelar Reserva"


    const id = this.reserva.id




    const data = {

      nombre: this.formGuardar.get('nombre').value,
      email: this.formGuardar.get('email').value,
      telefono: this.formGuardar.get('telefono').value,
      user_client_id: 'fecha bloqueada'

  }





    this.reservasService.guardarDatosBloqueados(id, index, data)
    .then(() => {
      this.showCancelButton[index] = false;



      this.formGuardar.reset();


    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });


  }

  guardarHora(index){
    const id = this.reserva.id;

    const formGroup = this.formHourList[index]; // Obtén el FormGroup correspondiente al índice

    const data = {
      hour_start: formGroup.get('hour_start').value, // Obtén el valor del campo hour_start del FormGroup
    };

    this.botonGuardarHora = false;


    this.reservasService.agregarDatos(id, data)
      .then(() => {
        this.reservasService.getuserby(id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((result: any) => {
            this.users = [result];
            this.hours = result.hours;

            this.formHourList = [];

            this.hours.forEach(item => {
              this.formHourList.push(this.setFormHour(item));
            });




          });
      })
      .catch((error) => {
        console.error('Error al actualizar el mapa', error);
      });
  }



  desbloquearReserva(index: number) {
    // this.showCancelButton[index] = false; // Ocultar el botón "Cancelar Reserva"
    // this.fechaBloqueada[index] = true; // Ocultar el botón "Cancelar Reserva"


    const hourList = [];


    const id = this.reserva.id

    const data = {

      user_client_id: '',

  }




    this.reservasService.bloquearFecha(id, index, data)
    .then(() => {

      this.showCancelButton[index] = false; // Ocultar el botón "Cancelar Reserva"


    })
    .catch((error) => {
      console.error('Error al actualizar el mapa', error);
    });


  }







  addHour(index) {


    if(this.reserva.hours){
      this.addHora(index)
      this.botonGuardarHora = true;

    } else {
      this.formHourList.push(this.setFormHour());


    }
  }

  asignarHour2(index: number) {
    const hourStart = this.formHourList[index].getRawValue().hour_start.split(':')[0];
    const hourEnd = this.formHourList[index].getRawValue().hour_end.split(':')[0];
    const newHourStart = Number(hourStart);
    const newHourEnd = Number(hourStart) + 1;
    let hourTempEnd = Number(hourStart) + 2;
    let hourTempStart = Number(hourStart) + 1;


    while (hourTempEnd <= Number(hourEnd)) {
      const hourStartForm = `${hourTempStart.toString().padStart(2, '0')}:00`;
      if (!this.formHourList.some(item => item.getRawValue().hour_start === hourStartForm)) {
        this.formHourList.push(this.setFormHour({
          hour_start: hourStartForm,
          hour_end: `${hourTempEnd.toString().padStart(2, '0')}:00`,
        }));
      }
      ++hourTempEnd;
      ++hourTempStart;

    }

    this.formHourList[index].patchValue({hour_start: `${newHourStart.toString().padStart(2, '0')}:00`});
    this.formHourList[index].patchValue({hour_end: `${newHourEnd.toString().padStart(2, '0')}:00`});
  }

  asignarHour(index: number) {


    const hourStart = this.formHourList[index].getRawValue().hour_start.split(':')[0];
    const hourEnd = this.formHourList[index].getRawValue().hour_end.split(':')[0];
    const minuteStart = this.formHourList[index].getRawValue().hour_start.split(':')[1];
    const minuteEnd = this.formHourList[index].getRawValue().hour_end.split(':')[1];

    const newHourStart = Number(hourStart);
    const newHourEnd = Number(hourStart) + 1;
    let hourTempEnd = Number(hourStart);
    let hourTempStart = Number(hourStart);
    let minuteTempEnd = Number(minuteStart);
    let minuteTempStart = Number(minuteStart);



    while (hourTempEnd < Number(hourEnd) || (hourTempEnd === Number(hourEnd) && minuteTempEnd <= Number(minuteEnd))) {
      const hourStartForm = `${hourTempStart.toString().padStart(2, '0')}:${minuteTempStart.toString().padStart(2, '0')}`;
      if (!this.formHourList.some(item => item.getRawValue().hour_start === hourStartForm)) {
        this.formHourList.push(this.setFormHour({
          hour_start: hourStartForm,
        }));

      }

      minuteTempEnd += 45;
      minuteTempStart += 45;

      if (minuteTempEnd >= 60) {
        minuteTempEnd -= 60;
        hourTempEnd += 1;
      }

      if (minuteTempStart >= 60) {
        minuteTempStart -= 60;
        hourTempStart += 1;
      }


    }

    this.ocultarEnd = false;


    this.formHourList[index].patchValue({hour_start: `${newHourStart.toString().padStart(2, '0')}:${minuteStart}`});



  }






  // deleteHour(index) {
  //   if (confirm('¿seguro?')) {
  //     if (this.formHourList.length === 1) {
  //       alert('Debe tener al menos una hora');
  //     } else {
  //       this.formHourList.splice(index, 1);
  //     }
  //   }
  // }

  limpiarHour(index) {
    Swal.fire({
      title: 'Vas a limpiar la hora...?',
      showCancelButton: true,
      confirmButtonText: 'Limpiar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.limpiar(index)
        Swal.fire('Eliminado', '', 'info')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  deleteHour(index){
    Swal.fire({
      title: 'Seguro que deseas eliminar la hora?',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.eliminarIndex(index)
        this.obtenerReserva()
        if (this.formHourList.length === 1) {
                alert('Debe tener al menos una hora');
              } else {
                this.formHourList.splice(index, 1);
              }
              Swal.fire('Eliminado', '', 'info')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  eliminarIndex(index: any) {
    const id = this.reserva.id;
    this.reservasService.eliminarDato(id, index)
      .then(() => {

      })
      .catch((error) => {
        console.error('Error al eliminar el índice', error);
      });
  }


  /**
   * Añade una reserva
   */
  addBookingFirebase() {
    const hourList = [];
    this.formHourList.forEach(form => {
      hourList.push({
        hour_start: form.value.hour_start,
        hour_end: form.value.hour_end,
        user_client_id: '',
        user_client_name: '',
      });
    });

    if (this.reserva.id) {
      const modelUpdate = {
        id: this.reserva.id,
        name: this.formBooking.getRawValue().name,
        city: this.formBooking.getRawValue().city,
        hours: hourList
      };



      this.reservasService.reservaUpdate(modelUpdate).then(() => {
        Swal.fire('Guardado')
        this.activeModal.close();
      }).catch((err) => {
        alert('error');
      });

    } else {
      const model = {
        name: this.formBooking.getRawValue().name,
        city: this.formBooking.getRawValue().city,
        hours: hourList,
        date: this.reserva.date,
        user_admin_id: JSON.parse(localStorage.getItem(environment.userReservas)).id,
        email: this.userModel.email


      };

      this.reservasService.reservaAdd(model).then(() => {
        Swal.fire('Reserva Agregada')
        this.activeModal.close();
      }).catch((err) => {
        alert('error');

      });
    }
  }


  deleteReserva(){
    const modelDelete = { id: this.reserva.id};

    Swal.fire({
      title: 'Estas seguro de que deseas eliminar todo?',

      showCancelButton: true,
      confirmButtonText: 'Eliminar',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.reservasService.reservaDelete(modelDelete).then(() => {

          this.activeModal.close()})
        Swal.fire('Eliminado', '', 'info')
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })





}



obtenerReserva(){

  const id = this.reserva.id

  this.reservasService.getDocument(id).toPromise()
  .then(doc => {
    this.reserva = doc.data() as BookingData;


  })
}


obtenerReserva2(){

  const id = this.reserva.id


  this.reservasService.getDocument2(id)
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(doc => {

    this.reserva = doc.payload.data()


  })
}



}
