<div class="fondo fondo2">



    <div class="row" style="padding-top: 12%;">
        <div class="col-lg-3">
        </div>
        <div class="col-lg-6">
            <h1 class="text-center text-white">Reservas E-ecl</h1>

        </div>

    </div>


    <div class="row">
        <!-- column -->
        <div class="col-lg-3 ">

        </div>

        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <!-- <h4 class="card-title">Reservas Disponibles</h4> -->
                    <div class="row">
                        <div class="col-lg-6 text-center">
                            <p class="card-text ">
                                <ngb-datepicker class="zoom-datepicker" #dp (navigate)="date = $event.next" [minDate]="{year: today.year, month: today.month, day: today.day + 1}" [maxDate]="{year: today.year, month: today.month + 3, day: 31}" [dayTemplate]="customDay">
                                    <ng-template #customDay let-date let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
                                        <span (click)="onDateSelect(date)" class="custom-day" [class.bg-primary]="selected" [class.text-muted]="disabled" [class.weekend]="isWeekend(date)" [class.semana]="isSemana(date)" [class.disponible]="isDisponible(date)" [class.noDisponible]="noDisponible(date)">
              {{ date.day }}
            </span>
                                    </ng-template>
                                </ngb-datepicker>
                            </p>

                        </div>

                        <div class="col-lg-6 text-center" style=" padding-top: 50px;">

                            <div *ngIf="!loadReservas">
                                <h4>
                                    Selecciona las fechas disponibles
                                </h4>
                            </div>

                            <div *ngIf="loadReservas">
                                <h4>
                                    No disponible
                                </h4>
                            </div>

                            <div *ngIf="hoursArray.length > 0">


                                <div *ngIf="!loadReservas">
                                    <ng-container *ngFor="let reserva of hoursArray;  let i = index">
                                        <p style="text-align: left;">
                                            Grupo {{i + 1}} - Lugar de reserva <strong style="font-weight: 900; color: #f46026">{{reserva.city}}</strong>
                                          </p>
                                        <ng-container *ngFor="let hour of reserva.hours;  let index = index" style="text-align: left;">
                                            <button *ngIf="!hour.user_client_id" style="width: 100px!important; margin: 5px;" (click)="meterDatos(i, index, reserva.city)" class="btn btn-lg fs-2 btn-success"> {{ hour.hour_start }} </button>
                                        </ng-container>
                                        <br>
                                        <hr>

                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>

            <div class="col-lg-4">

            </div>






        </div>



        <div class="col-lg-3 ">

        </div>




        <div class="row" style="padding-top: 100px;">
            <!-- column -->
            <div class="col-lg-3">

            </div>

            <div class="col-lg-3">
                <div class="card">
                    <img class="card-img-top img-responsive" src="https://qualiflight.aero/wp-content/uploads/2020/04/GettyImages-171483024.jpg" alt="Card image cap" />
                    <div class="card-body">
                        <h2 class="card-title">Consejos para preparar el examen de Competencia Lingüística
                        </h2>
                        <p class="card-text">
                            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                                <ngb-panel id="static-1" class="title-acordeon">
                                    <ng-template ngbPanelTitle class="text-black">
                                        Antes del examen
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ul>
                                            <li>Asegúrate de venir provisto de tu DNI ó pasaporte</li>
                                            <li>Deberás venir provisto con una foto de tu licencia</li>
                                            <li>Descansa bien el día anterior</li>
                                            <li>Acude relajado y tranquilo</li>
                                            <li>Recuerda que el examen será grabado en audio y vídeo</li>
                                            <li>Pregunta todas tus dudas a tu evaluador antes del comienzo del examen</li>
                                        </ul>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-2" class="title-acordeon">
                                    <ng-template ngbPanelTitle>
                                        Parte 1 : Listening
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ul>
                                            <li>Escucharás 5 grabaciones de audio. Después de escuchar cada una dos veces, tendrás que responder a dos preguntas por cada audio (30 segundos/pregunta)</li>
                                            <li>Son grabaciones reales.</li>
                                            <li>Deberás tomar nota de los audios para poder contestar ca las preguntas.</li>

                                        </ul>
                                    </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-3" class="title-acordeon">
                                    <ng-template ngbPanelTitle>
                                        Parte 2 : Role Play
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ul>
                                            <li>En esta parte de la prueba, asumirás el rol que desempeñas en la operación normal (piloto / ATC) y el examinador asumirá la función de piloto, ATC, ground crew, TCP, etc... Según requiera la escena elegida.</li>
                                            <li>Durante la prueba, tendrás que colacionar las instrucciones que te proporcione el examinador y actuar tal y como actuarías en la vida real.</li>
                                            <li>Esta parte durará aproximadamente 6 minutos.</li>
                                            <li>En esta parte no habrá contacto visual con el examinador.</li>
                                        </ul>
                                    </ng-template>
                                </ngb-panel>

                                <ngb-panel id="static-4">
                                    <ng-template ngbPanelTitle class="title-acordeon">
                                        Parte 3 : Entrevista personal
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ul>
                                            <li>
                                                En esta parte se hablará lenguaje común, no aeronáutico.
                                            </li>
                                            <li>Será una entrevista donde el evaluador te irá haciendo preguntas de tipo profesional</li>
                                            <li>Intenta no contestar con monosílabos, ni tampoco con estructuras gramaticales demasiado complejas</li>
                                            <li>Realiza un mensaje simple e informativo. Si no estás seguro de cómo expresar algo o no conoces el vocabulario, intenta parafrasear (describir lo que quieres decir empleando otras palabras que sí conozcas)</li>
                                            <li>Algunas preguntas versan sobre ti: por qué decidiste ser controlador / piloto, a qué te hubieras dedicado si no hubieras escogido la profesión de controlador / piloto, tu trabajo actual, qué es lo que más te
                                                gusta y disgusta de volar o controlar. También es posible que se te pregunte acerca de tu opinión en lo referente a la futura profesión aeronáutica.</li>
                                            <li>Una idea para prepararte esta parte del examen puede ser como si te prepararas una entrevista de trabajo</li>
                                            <li>
                                                Tendrá una duración aproximada de 6 minutos.
                                            </li>

                                            <li>En esta parte habrá contacto visual con el examinador.
                                            </li>
                                        </ul>
                                    </ng-template>
                                </ngb-panel>

                                <ngb-panel id="static-5" class="title-acordeon">
                                    <ng-template ngbPanelTitle>
                                        Calificación de la prueba
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <ul>
                                            <li>La calificación global de la prueba, de acuerdo a los estándares OACI, será la calificación más baja de los descriptores integrales de OACI:</li>
                                            <ul>
                                                <li>Pronunciación</li>
                                                <li>Estructura</li>
                                                <li>Vocabulario</li>
                                                <li>Fluidez</li>
                                                <li>Comprensión</li>
                                                <li>Interacción</li>
                                            </ul>
                                            <li>Al término de la prueba, el evaluador te notificará tu calificación.</li>
                                        </ul>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="card">
                    <img class="card-img-top img-responsive" src="https://qualiflight.aero/wp-content/uploads/2020/04/6db4a2309893459780cee0fb836b909d-1024x1024.jpg" alt="Card image cap" />
                    <div class="card-body">
                        <h3 class="card-title">Contacto</h3>
                        <div class="card-text">
                            <h4>Solicitud de cita Madrid: <br> 
                                <img style="width:35px;" src="https://cdn-icons-png.flaticon.com/512/174/174879.png" alt="WhatsApp"> <a href="https://wa.me/34617713006">  +34 617 713 006</a> <br>
                                <img style="width:35px;" src="https://cdn-icons-png.flaticon.com/512/174/174879.png" alt="WhatsApp"> <a href="https://wa.me/34617812856"> +34 617 812 856</a>

                            </h4> 
                            <h4>Solicitud de cita Málaga : <br>
                                <img style="width:35px;" src="https://cdn-icons-png.flaticon.com/512/174/174879.png" alt="WhatsApp"> <a href="https://wa.me/34671668197">  +34 671 668 197</a> <br>
                            </h4>
                            <p>Solicitamos que las citas se programen a través de nuestro sitio web. En caso de consultas sobre sus reservas, no dude en ponerse en contacto con nosotros mediante el número de teléfono proporcionado anteriormente.</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-3">

            </div>

            <!-- ---------------------
                    start Card title
                ---------------- -->



        </div>


        <footer class="footer text-center">
            All Rights Reserved by
            <a href="https://www.qualiflight.aero">Qualiflight</a>.
        </footer>



    </div>