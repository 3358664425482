<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" dir="ltr" data-theme="light" data-layout="vertical" [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full" *ngIf="authService.isAuthenticated()">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->
    <header class="topbar bg-gradient" data-navbarbg="skin1">
        <nav class="d-flex top-navbar navbar-expand-lg navbar-dark">
            <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" data-logobg="skin6">
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <div *ngIf="showMobileMenu == false">
                    <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none" href="javascript:void(0)" *ngIf="authService.isAuthenticated()">
                        <i [ngClass]="showMobileMenu ? 'bi bi-text-left fs-6' : 'bi bi-text-left fs-6'"></i>
                    </a>
                </div>

                <div *ngIf="showMobileMenu == true">
                    <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none" href="javascript:void(0)" *ngIf="authService.isAuthenticated()">
                        <i [ngClass]="showMobileMenu ? 'bi bi-x fs-6' : 'bi bi-x fs-6'"></i>
                    </a>
                </div>
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <a class="navbar-brand" href="/">
                    <!-- Logo icon -->
                    <b class="logo-icon">
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Light Logo icon -->
          <!-- <img
            src="assets/images/logos/logo-icon.png"
            alt="homepage"
            class="dark-logo"
          /> -->
        </b>
                    <!--End Logo icon -->
                    <!-- Logo text -->
                    <span class="logo-text">
          <!-- Light Logo text -->
          <img
            src="https://qualiflight.aero/wp-content/uploads/2020/04/Qualiflight-aviation-training-05-05-300x83.png"
            class="dark-logo" style="width: 85%;"
            alt="homepage"
          />
        </span>
                </a>
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Toggle which is visible on mobile only -->
                <!-- ============================================================== -->


                <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed">
                    <i class="bi bi-text-right fs-6"></i>
                </a>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin1" [ngbCollapse]="!isCollapsed">
                <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
            </div>
        </nav>
    </header>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar" data-sidebarbg="skin6" (mouseover)="Logo()" (mouseout)="Logo()">
        <app-sidebar></app-sidebar>
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class="page-wrapper">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>

        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <footer class="footer text-center">
            All Rights Reserved by
            <a href="https://www.qualiflight.aero">Qualiflight</a>.
        </footer>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
</div>


<div id="main-wrapper" [ngClass]="{ 'show-sidebar': showMobileMenu }" dir="ltr" data-theme="light" data-layout="vertical" [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full" *ngIf="!authService.isAuthenticated()">
    <header class="topbar bg-gradient" data-navbarbg="skin1">
        <nav class="d-flex top-navbar navbar-expand-lg navbar-dark">
            <div class="navbar-header" [ngClass]="expandLogo ? 'expand-logo' : ''" data-logobg="skin6">
                <!-- This is for the sidebar toggle which is visible on mobile only -->
                <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none" href="javascript:void(0)" *ngIf="authService.isAuthenticated()">
                    <i [ngClass]="showMobileMenu ? 'bi bi-text-left fs-6' : 'bi bi-text-left fs-6'"></i>
                </a>
                <!-- ============================================================== -->
                <!-- Logo -->
                <!-- ============================================================== -->
                <a class="navbar-brand" href="/">
                    <!-- Logo icon -->
                    <b class="logo-icon">
      <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
      <!-- Light Logo icon -->
      <!-- <img
        src="assets/images/logos/logo-icon.png"
        alt="homepage"
        class="dark-logo"
      /> -->
    </b>
                    <!--End Logo icon -->
                    <!-- Logo text -->
                    <span class="logo-text">
      <!-- Light Logo text -->
      <img
        src="https://qualiflight.aero/wp-content/uploads/2020/04/Qualiflight-aviation-training-05-05-300x83.png"
        class="dark-logo" style="width: 85%;"
        alt="homepage"
      />
    </span>
                </a>
                <!-- ============================================================== -->
                <!-- End Logo -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Toggle which is visible on mobile only -->
                <!-- ============================================================== -->
                <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed">
                    <i class="bi bi-text-right fs-6"></i>
                </a>
            </div>
            <!-- ============================================================== -->
            <!-- End Logo -->
            <!-- ============================================================== -->
            <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin1" [ngbCollapse]="!isCollapsed">
                <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
            </div>
        </nav>
    </header>

    <div class="fondo">

        <div class="container-fluid">
            <app-landing></app-landing>
        </div>



    </div>



</div>