<div class="fondo d-flex align-items-center justify-content-center">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-title">
                        <h3>Código de reserva</h3>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="formulario">
                            <label  *ngIf="!reserva_data" for="codigoReserva">Por favor, para modificar la reserva inserta el código de reserva que te hemos enviado al email. Ten en cuenta los espacios en blanco</label>
                            <input type="text" placeholder="Por favor, inserta tu código de reserva" formControlName="codigoReserva" class="form-control" id="codigoReserva" />
                        </form>
                    </div>

                    <div class="card-body" *ngIf="reserva_data" style="margin-top: 50px;">
                        <ul style="list-style-type: none; padding: 0;">
                            <li style="margin-bottom: 10px;">
                                <strong>Nombre:</strong> {{reserva_data?.nombre}}

                                
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Apellidos:</strong> {{reserva_data?.apellidos}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Email:</strong> {{reserva_data?.email}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>DNI:</strong> {{reserva_data?.dni}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Hora de inicio:</strong> {{reserva_data?.hour_start}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Examen:</strong> {{reserva_data?.examen}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Licencia:</strong> {{reserva_data?.licencia}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Teléfono:</strong> {{reserva_data?.telefono}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Tipo:</strong> {{reserva_data?.tipo}}
                            </li>
                            <li style="margin-bottom: 10px;">
                                <strong>Tramitación:</strong> {{reserva_data?.tramitacion}}
                            </li>
                        </ul>



                

                    </div>
                    
                    
                    
                    
                    <button *ngIf="reserva_data" style="margin-top: 50px;" class="btn btn-danger" type="button" (click)="cancelarReserva()">Cancelar reserva</button>

                    <button *ngIf="!reserva_data" style="margin-top: 50px;" class="btn btn-primary" type="button" (click)="verBloqueo()">Ver mi reserva</button>




                </div>
            </div>
            <div class="col-lg-4">
            </div>
        </div>
    </div>
</div>