import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../../environments/environment';
import { UserModel } from '../models/user-model';
import firebase from 'firebase/compat';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    public db: AngularFirestore,
    private afAuth: AngularFireAuth,
  ) {
  }

  resetPasswordEmail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.afAuth.sendPasswordResetEmail(email)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  authSingUp(email: string, password: string): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(res => {
          this.db.collection<UserModel>('users').add({
            id: '',
            name: email,
            email,
            role: 'client',
          }).then((ref) => {
            ref.update({
              id: ref.id
            });
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  authUpdatePass(password: firebase.User): Promise<void> {
    return this.afAuth.updateCurrentUser(password);
  }

  authLogin(email: string, password: string): Promise<void> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(res => {
          this.db.collection<UserModel>('users', ref => {
            return ref
              .where('email', '==', email);
          }).get().subscribe(result => {
            if (!result.empty) {
              const userModel = result.docs[0].data();
              if (userModel.email === email) {
                localStorage.setItem(environment.userReservas, JSON.stringify(userModel));
                resolve(res);
              } else {
                this.logout();
                reject();
              }
            } else {
              this.logout();
              reject();
            }
          }, err => {
            this.logout();
            reject(err);
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  logout(): Promise<void> {
    localStorage.removeItem(environment.userReservas);
    return this.afAuth.signOut();
  }

  /**
   * Indico si el usuario esta o no logueado por el localstorage
   */
  isAuthenticated() {
    return localStorage.getItem(environment.userReservas);
  }

}
